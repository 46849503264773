p {
  line-height: 1.65;
}

p, ul {
  font-size: 1.05rem;
}

.inlineB {
  display: inline-block;
}

/*hide item*/
.hide{
  display: none;
}

/*text colors*/
.blueText {
  color: #6af;
}
.greenText {
  color: #d0ffc0;
}
.lightBlueText,
.lightblueText {
  color: lightblue;
}
.lightGreenText,
.lightgreenText {
  color: #e0ffd0;
}
.lightPinkText,
.lightpinkText {
  color: lightpink;
}
.lightYellowText,
.lightyellowText {
  color: lightyellow;
}
.pinkText {
  color: lightpink;
}
.redText {
  color: #ffa0a0;
}
.whiteText {
  color: #fee;
}
.yellowText {
  color: #ffe0a0;
}
/*end of text colors*/
